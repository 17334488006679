//For variants in the cart with stock_selection_deferred, make options optional if removing the item from cart

import { field_required } from "app/shared/shared_helpers.js";

$(document).on("change", 'input.remove-line-item[type="checkbox"]', function() {
  var $container, required;
  required = !$(this).is(":checked");
  $container = $(this).closest("tr");
  field_required($container.find("select.option-type-select"), required);
  rebuild_parsley($(this).closest("form"));
});

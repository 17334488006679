require('app/utilities/bugsnag')

import * as ActiveStorage from "@rails/activestorage"
import Rails from "@rails/ujs"

ActiveStorage.start()
Rails.start()

require('jquery')
require("bootstrap")

// fix variant price popover
$.fn.popover.Constructor.Default.whiteList.table = [];
$.fn.popover.Constructor.Default.whiteList.tr = [];
$.fn.popover.Constructor.Default.whiteList.td = [];
$.fn.popover.Constructor.Default.whiteList.div = [];
$.fn.popover.Constructor.Default.whiteList.tbody = [];
$.fn.popover.Constructor.Default.whiteList.thead = [];

import "/tmp/build_04602763/vendor/bundle/ruby/3.3.0/gems/cocoon-1.2.15/app/assets/javascripts/cocoon"
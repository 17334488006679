import { init_bootstrap } from '../helpers';
import { start_timer } from './timer.js';

$(document).on('click', '.mailcheck-btn', function() {
  const $email_field = $(this).closest('small.form-text').siblings('input[type="email"]');
  $email_field.val($(this).data('email'));
  return $(this).closest('small.form-text').html('');
});

$(document).on('input', 'input[type="email"]', function() {
  const $el = $(this);
  return $el.mailcheck({
    suggested(element, suggestion) {
      const string = "Did you mean <a class=\"mailcheck-btn text-success\" data-email=\"" + suggestion.full + '">' + suggestion.full + '</a>?';
      return $(element).siblings('small.form-text:first').html(string);
    },
    empty(element) {
      const $help = $(element).siblings('span.help-block');
      return $help.html('');
    }
  });
});

$(document).on('click', '.coupon-code-btn', function() {
  $(this).hide();
  $('.coupon-code-field').removeClass('d-none');
  $(this).closest('form').find('input[type="submit"]').removeClass('d-none');
  return false;
});

const checkout_ready = function() {
  const $timer = $('.checkout-timer');
  if ($timer.length) {
    start_timer($timer);
  }

  const $container = $('body');
  init_bootstrap($container);

  init_phones($container);
  add_selectize_fields($container);

  $('[data-toggle="tooltip"]').tooltip();

  if (window !== window.parent) {
    // we need to open up a new window first
    const link = '<a href="' + window.location.href + '" target="_blank" class="btn btn-block btn-lg btn-success">Pay Now (opens in new window first)</a>';
    $('.beanstream.interac').replaceWith(link);
  }

  const $forms = $('form');
  if ($forms.length) { $forms.parsley(); }

  const $browser_not_supported = $('.order_set_cookie');

  if (document.cookie.indexOf('tpt') === -1) { // Not found
    return $browser_not_supported.removeClass('d-none');
  }
};

$(document).ready(checkout_ready);

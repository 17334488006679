$(document).on('click', '.checkout-button', function() {
  if (typeof fbq !== 'undefined' && fbq !== null) {
    return fbq('track', 'InitiateCheckout');
  }
});

$(document).on('click', '.payment-button', function() {
  if (typeof fbq !== 'undefined' && fbq !== null) {
    return fbq('track', 'AddPaymentInfo');
  }
});

const facebook_pixel = function() {
  if ((typeof fbq !== 'undefined' && fbq !== null) && window.order && (window.order.state === 'complete')) {
    return fbq('track', 'Purchase', {
      value: parseFloat(window.order.total),
      currency: window.order.currency
    }
    );
  }
};

$(document).ready(facebook_pixel);

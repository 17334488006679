import { init_bootstrap } from '../helpers';
const option_value_ids_from_product = function($container) {
  const option_value_ids = [];
  $container.find('.option-type-select').each(function() {
    const int = parseInt( $(this).val() );
    if (int > 0) {
      return option_value_ids.push(int);
    }
  });
  return option_value_ids;
};

const product_container = $option_type => $option_type.closest('.product-option-types');

// update the price for a collection of option types
$(document).on('change', '.option-type-select', function() {
  const product_id = $(this).data('product-id');
  const $product_container = product_container( $(this) );
  const option_value_ids = option_value_ids_from_product( $product_container );
  if (!option_value_ids || !(option_value_ids.length > 0)) { return; }
  const $price_container = $('.product-price-' + product_id );

  return $.ajax({
    type: 'GET',
    url: '/api/frontend/products/' + product_id + '/price',
    data: {
      option_value_ids
    },
    success(data) {
      if (data.price) {
        $price_container.html(data.price.html);
        return init_bootstrap($price_container);
      } else {
        return $price_container.html('');
      }
    }
  });
});

// update the images for a collection of option types
$(document).on('change', '.option-type-select', function() {
  const product_id = $(this).data('product-id');

  const $product_container = product_container( $(this) );
  const option_value_ids = option_value_ids_from_product( $product_container );

  return $.ajax({
    type: 'GET',
    url: '/api/frontend/products/' + product_id + '/images',
    data: {
      option_value_ids
    },
    success(images) {
      const image = images[0];
      const img = $('img.product-image-' + product_id);
      if (image) {
        img.attr('alt', image.alt);
        return img.attr('src', image.large_url);
      }
    }
  });
});
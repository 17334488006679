const sessions_ready = function() {

  const $calendars = $('.recurring-calendar-picker');
  $calendars.each(function(index, calendar) {
    const $calendar = $(calendar);
    const url = $calendar.data('url');
    $calendar.fullCalendar({
      header: {
        left: 'title',
        center: '',
        right: 'today prev,next'
      },
      buttonText: {
        today: 'Today'
      },
      eventClick(event, js_event, view) {
        const link = event.links.order_page;
        $.getScript(link);
      },
      eventRender(event, $element) {
        $element.append(event.venue.name);
      },
      events: {
        url,
        data: {
          per_page: 100,
          fields: 'id,start,end,name,links',
          include: 'venue'
        },
        headers: {
          'Authorization': 'Token ' + window.api_token
        },
        startParam: 'start_date[range_start]',
        endParam: 'start_date[range_end]',
        eventDataTransform(event) {
          event.title = event.name;
          event.start = event.start.local;
          event.end = event.end.local;
          return event;
        }
      }
    });
  });
};

$(document).on('change', 'select.event-session-date-input', function() {
  const date = $(this).val();
  const $form = $(this).closest('form');
  const $time_select = $form.find('select.event-session-time-input');
  $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'json',
    data: {
      date
    },
    success(data) {
      let html = '<option value disabled="disabled">Time...</option>';
      for (let event of Array.from(data)) {
        html += '<option data-link="' + event.link + '" value="' + event.id + '">' + event.time + '</option>';
      }
      $time_select.empty().append(html);
      $time_select.prop('disabled', false);
      return $time_select[0].selectedIndex = 0;
    }
  });
});

$(document).on('change', 'select.event-session-time-input', function() {
  const link = $(this).find('option:selected').data('link');
  $.getScript(link);
});

$(document).ready(sessions_ready);
const update_keep_ticket = function($input) {
  const $container = $input.closest('.ticket-fields');
  const $name_field = $container.find('input.ticket-name-field');
  const $email_field = $container.find('input.ticket-email-field');
  const $auto_transfer_field = $container.find('.auto-transfer-label');
  if ($input.is(':checked')) {
    $name_field.val($name_field.data('guest-name'));
    $email_field.val($email_field.data('guest-email'));
    $name_field.attr('readonly', true);
    $email_field.attr('readonly', true);
    $name_field.attr('disabled', true);
    $email_field.attr('disabled', true);
    return $auto_transfer_field.addClass('d-none');
  } else {
    $name_field.val($name_field.data('value'));
    $email_field.val($email_field.data('value'));
    $name_field.attr('readonly', false);
    $email_field.attr('readonly', false);
    $name_field.attr('disabled', false);
    $email_field.attr('disabled', false);
    return $auto_transfer_field.removeClass('d-none');
  }
};

$(document).on('change', 'input.keep-ticket', function() {
  return update_keep_ticket($(this));
});

const ready = function() {
  const $keep_inputs = $('input.keep-ticket');
  $keep_inputs.each((index, input) => update_keep_ticket($(input)));

  // Ensure keep checkboxes only appear for the maximum setting to prevent errors
  const events = {};
  $keep_inputs.each(function(index, input) {
    const event_id = $(input).data('event-id');

    if (!events.hasOwnProperty(event_id)) {
      events[event_id] = {};
    }

    const ticket_type_id = $(input).data('ticket-type-id');
    if (events[event_id].hasOwnProperty(ticket_type_id)) {
      return events[event_id][ticket_type_id].push(input);
    } else {
      return events[event_id][ticket_type_id] = [input];
    }});

  return (() => {
    const result = [];
    for (let event_id in events) {
      var ticket_types = events[event_id];
      result.push((() => {
        const result1 = [];
        for (let ticket_type_id in ticket_types) {

          const inputs = ticket_types[ticket_type_id];
          const max = parseInt($(inputs[0]).data('maximum'));

          if (inputs.length > max) {
            const $boxes = $(inputs.slice(max, inputs.length));
            result1.push($boxes.closest('.ticket-transfer-preferences').addClass('d-none'));
          } else {
            result1.push(undefined);
          }
        }
        return result1;
      })());
    }
    return result;
  })();
};

$(document).ready(ready);
const forms_ready = () => $('.form-response input, .form-response select').each(function(input) {
  $(this).prop('disabled', 'disabled');
});

$(document).on('click', '.product-option', function() {
  $(this).parents('.form-group').find('.product-option').removeClass('selected');
  $(this).addClass('selected');
  $(this).parents('.form-group').find('span.badge').addClass('hidden');
  $(this).find('span.badge').removeClass('hidden');
  $(this).find('input[type="radio"]').attr('checked', true);

});

$(document).ready(forms_ready);

$(document).on('click', '.btn-facebook-send', function() {
  const url = $(this).data('url');
  return FB.ui({
    method: 'send',
    link: url
  });
});

$(document).on('click', '.facebook-share-button', function() {
  const url = $(this).data('url');
  const id = $(this).data('id');
  if (!url || !id) {
    notify('info', 'Please register or login first.');
    return;
  }
  const $button = $(this);
  return FB.getLoginStatus(function(response) {
    if (response.status === 'connected') {
      return facebook_share(url, id);
    } else {
      return facebook_login(function(response) {
        if (response.status === 'connected') {
          return facebook_share(url, id);
        } else {
          return notify('info', 'Must be logged into Facebook to share.');
        }
      });
    }
  });
});
$(document).on('change', 'select.invite-quantity-select', function() {
  const quantity = $(this).val();
  const $container = $(this).closest('.invite-form-container');
  $container.find('a.remove-ticket-btn').siblings('input[type="hidden"]').attr('value', 'true');
  //debugger
  if (quantity) {
    $container.find('form').removeClass('d-none');
    const $fields = $('.ticket-fields').addClass('d-none').slice(0, quantity);
    $fields.find('a.remove-ticket-btn').siblings('input[type="hidden"]').attr('value', 'false');
    return $fields.removeClass('d-none');
  } else {
    return $container.find('form').addClass('d-none');
  }
});

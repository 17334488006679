$(document).on('ajax:success', '.logout-button', function(event) {
  const $billing_email_field = $(this).parents('.form-group').find('input');
  $billing_email_field.val('');
  $billing_email_field.prop('readonly', false);
  return $(this).remove();
});

$(document).on('click', 'a.stock-item-more-info-btn', function(e) {
  e.preventDefault();
  const selector = '#stock-item-more-info-' + $(this).data('id');
  return $(selector).toggleClass('d-none');
});
const password_modal = '#user-password-modal';
const user_login_modal = '#user-login-modal';
const presale_password_modal = '#presale-password-modal';
const invite_intercept_modal = '#invite-intercept-modal';

$(document).on('ajax:success', '.invite-intercept-form', function(event) {
  const data = event.detail[0];
  if (data.success) {
    return $(invite_intercept_modal).modal('toggle');
  } else {
    $('.invite-message').addClass('d-none');
    return $('.invalid-invite-number').removeClass('d-none');
  }
});

$(document).on('ajax:success', '.create-invite-form', function(event) {
  $('.invite-created').removeClass('d-none');
  return $('.submit-invite-btn').fadeOut();
});

$(document).on('ajax:success', '.set-password-form', () => $(password_modal).modal('hide'));
$(document).on('ajax:error', '.set-password-form', () => window.location.reload());

$(document).on('ajax:success', '.presale-password-form', function(event) {
  const data = event.detail[0];
  if (data.success) {
    if (data.redirect) {
      return window.location.href = data.redirect;
    } else {
      $('input.presale-password-field').val(data.password);
      return $(presale_password_modal).modal('toggle');
    }
  } else {
    return $('.invalid-presale-password').removeClass('d-none');
  }
});

const password_ready = function() {
  const $password_modal = $(password_modal);
  if ($password_modal.length) {
    $password_modal.modal({
      backdrop: 'static'});
  }

  const $user_login_modal = $(user_login_modal);
  if ($user_login_modal.length) {
    $user_login_modal.modal({
      backdrop: 'static'});
  }

  const $presale_password_modal = $(presale_password_modal);
  if ($presale_password_modal.length) {
    $presale_password_modal.modal({
      backdrop: 'static'});
  }

  const $invite_intercept_modal = $(invite_intercept_modal);
  if ($invite_intercept_modal.length) {
    return $invite_intercept_modal.modal({
      backdrop: 'static'});
  }
};

$(password_ready);
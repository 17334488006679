export var start_timer = function($display) {
  const expires_at = parseInt($display.data('expires-at'));
  const end = new Date(expires_at * 1000);
  const _second = 1000;
  const _minute = _second * 60;
  const _hour = _minute * 60;
  const _day = _hour * 24;
  let timer = undefined;

  const showRemaining = function() {
    const now = new Date;
    const distance = end - now;
    if (distance < 0) {
      clearInterval(timer);
      $display.find('span').html('Expired!');
      window.location.href = '/checkout';
      return;
    }
    const days = Math.floor(distance / _day);
    const hours = Math.floor((distance % _day) / _hour);
    const minutes = Math.floor((distance % _hour) / _minute);
    let seconds = Math.floor((distance % _minute) / _second);
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    $display.find('span').html(minutes + ':' + seconds);
    $display.removeClass('d-none');
    if (minutes < 1) {
      return $display.find('span').addClass('text-danger');
    } else if (minutes < 5) {
      return $display.find('span').addClass('text-warning');
    }
  };


  return timer = setInterval(showRemaining, 1000);
};
document.addEventListener("DOMContentLoaded", () => {

  let form = document.querySelector('.order-edit-form')

  if (!form) { return }

  let event_select = form.querySelector('.event-select')

  // event_select.addEventListener('change', () => {
  //   console.log('change')
  // });
});

let date_select = 'select.date-select'
let time_select = 'select.time-select'
let ticket_select = 'select.ticket-select'

function data_url(endpoint) {
  return `${$('select.event-select').data('url')}/${endpoint}`
}

$(document).on('change', 'input.change-box-box', function() {
  $container = $(this).closest('.change-box-container').find('.change-box');
  if (this.checked) {
    $container.removeClass('d-none')
    $container.find('select').attr('required', true)
  } else {
    $container.addClass('d-none')
    $container.find('select').removeAttr('required')

  }
});

$(document).on('change', 'select.event-select', function() {
  console.log('event change');

  let event_id = $(this).val()

  let $date_select = find_field($(this), date_select)

  $date_select.data('event-id', event_id)

  let $time_select = find_field($(this), time_select);
  let $ticket_select = find_field($(this), ticket_select);

  let type = $(this).find(":selected").data('type')

  if (type == 'Event::Date') {

    $time_select.closest('.form-group').hide()
    $date_select.closest('.form-group').hide()
    $time_select.data('event-id', event_id)

    toggle_field($ticket_select, false);
    $time_select.trigger('change');

  } else {
    $time_select.closest('.form-group').show()
    $date_select.closest('.form-group').show()
    toggle_field($time_select, true);
    toggle_field($ticket_select, true);

    preflight_field($date_select);

    $.ajax({
      type: 'GET',
      url: data_url('dates'),
      dataType: 'json',
      data: {
        event_id
      },
      success(data) {
        console.log(data)

        let html = '';
        for (let date of Array.from(data)) {
          html += '<option value="' + date.value + '">' + date.label + '</option>';
        }
        $date_select.append(html);

        preflight_field($date_select, false);

      }
    });
  }

});

$(document).on('change', date_select, function() {
  console.log('date change')

  let event_id = $(this).data('event-id')
  let date = $(this).val()

  let $time_select = find_field($(this), time_select)

  $time_select.data('date', date)
  $time_select.data('event-id', event_id)

  let $ticket_select = find_field($(this), ticket_select)

  if (date == 'pass') {
    toggle_field($time_select, true);
    toggle_field($ticket_select, false);
    $time_select.trigger('change');
  } else {
    toggle_field($ticket_select, true);

    preflight_field($time_select);

    $.ajax({
      type: 'GET',
      url: data_url('times'),
      dataType: 'json',
      data: {
        event_id, date
      },
      success(data) {
        console.log(data)

        let html = '';
        for (let date of Array.from(data)) {
          html += '<option value="' + date.value + '">' + date.label + '</option>';
        }
        $time_select.append(html);
        preflight_field($time_select, false);
      }
    });
  }


});

$(document).on('change', time_select, function() {
  console.log('time change')

  let date_id = $(this).val()

  let event_id = $(this).data('event-id')
  let $ticket_select = find_field($(this), ticket_select)
  $ticket_select.data('date-id', date_id)

  preflight_field($ticket_select);

  $.ajax({
    type: 'GET',
    url: data_url('tickets'),
    dataType: 'json',
    data: {
      event_id, date_id
    },
    success(data) {
      console.log(data)

      for (let date of Array.from(data)) {
        let option = new Option(date.label, date.value);
        if (date.disabled) {
          option.disabled = true
        }
        $ticket_select.append(option);
      }
      preflight_field($ticket_select, false);
    }
  });

});

$(document).on('change', ticket_select, function() {
  console.log('ticket change')
});

function find_field($container, selector) {
  return $container.closest('.card').find(selector);
}

function toggle_field($field, disabled) {
  if (disabled) {
    $field.val('')
    $field.prop('disabled', disabled);
    $field.find('option:not(:first)').remove();
  } else {
    $field.removeAttr('disabled');
  }
}

function preflight_field($field, loading = true) {
  if (loading) {
    toggle_field($field, true);
    $field.closest('.form-group').find('label span').removeClass('invisible');
  } else {
    toggle_field($field, false);
    $field.closest('.form-group').find('label span').addClass('invisible');

  }

}
window.facebook_share = (url, list_id) => FB.ui({
  method: 'share',
  href: url
}
, function(response) {
  if (response && !response.error_code) {
    return $.ajax({
      type: 'POST',
      url: '/api/frontend/shares',
      data: {
        share: {
          list_id,
          response_id: response.post_id
        }
      },
      success(data) {
        return window.location.reload();
      }
    });
  }
});

window.facebook_logged_in = function(graph) {
  if (!graph.picture) { return ''; }
  let result = '<div class="facebook-user media pull-left" style="width:160px">';
  result += '<img src="' + graph.picture.data.url + '" class="rounded-circle pull-left" />';
  result += '<h6 style="font-weight:bold">' + graph.name + '</h6>';
  return result += '</div>';
};
window.facebook_check_state = function() {

  if (window.logged_in) {
    $('.btn-facebook-login').data('reauth', false);
    return $('.btn-facebook-login').hide();
  } else {
    return $('.btn-facebook-login').data('reauth', true);
  }
};

window.facebook_login_event = function(response) {
  //uid = response.authResponse.userID
  //accessToken = response.authResponse.accessToken
  //signedRequest = response.authResponse.signedRequest
  if (response.status === 'connected') {
    let has_email = false;
    const scopes = response.authResponse.grantedScopes;
    if (scopes) {
      let needle;
      has_email = (needle = 'email', Array.from(scopes.split(',')).includes(needle));
      return facebook_check_state();
    } else {
      return FB.api('/me', {fields: 'id,email,name,picture'}, graph => facebook_check_state());
    }
  } else if (response.status === 'not_authorized') {}
    // loggedinto facebook, not our app
  else {}
};
    // unknown

window.facebook_login = function(callback, reauth) {
  if (reauth == null) { reauth = false; }
  const options = {
    scope: 'publish_actions,email,user_birthday',
    return_scopes: true
  };
  if (reauth) {
    options.auth_type = 'rerequest';
  }
  return FB.login((function(response) {
    if (callback) { return callback(response); }
  }), options);
};